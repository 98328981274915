<template>
  <b-card>
    <b-col class="form-container">
      <h4 class="mb-2">Zgłoszenie klienta</h4>
      <b-form-group label="Zgłaszający">
        <b-form-input v-model="chatRecord.name" disabled />
      </b-form-group>
      <b-form-group label="Email">
        <b-form-input v-model="chatRecord.email" disabled />
      </b-form-group>
      <b-form-group label="Data zgłoszenia">
        <b-form-input v-model="chatRecord.created_at" disabled />
      </b-form-group>
      <b-form-group label="Wiadomość">
        <b-form-textarea v-model="chatRecord.message" rows="8" disabled />
      </b-form-group>
    </b-col>
  </b-card>
</template>
<script>
import { show } from '@/http/chat';
export default {
  data() {
    return {
      chatRecord: {
        name: '',
        email: '',
        phone: '',
        message: '',
        created_at: '',
      },
    };
  },
  created() {
    this.fetchChatRecord();
  },
  methods: {
    async fetchChatRecord() {
      try {
        const { data } = await show(this.$route.params.id);
        this.chatRecord = data.data;
      } catch (error) {
        this.$toast.error({
          title: 'Błąd',
          message: 'Nie udało się pobrać danych',
        });
      }
    },
  },
};
</script>
